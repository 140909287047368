import React, { useState, useMemo } from "react";
import { FaChevronDown } from "react-icons/fa";
import { Accordion } from "./Accordion";

// We import your "hardcoded" collections:
import { material } from "./config";
import struttura from "./collections/struttura";
import inserti from "./collections/inserti";

export function DynamicAccordion({
  type,
  technicalName,
  selected,
  onChange,
  customConfig,
  ripianiFinishType,
  onRipianiFinishToggle,
}) {
  const [isOpen, setIsOpen] = useState(false);

  // Get wood materials for ripiani
  const woodMaterials = useMemo(() => {
    return (
      struttura.filter((collection) => collection.category === "wood")[0]
        ?.items || []
    );
  }, []);

  // This mapping is for the "title" only
  const titleMapping = {
    fabric: "Rivestimento",
    structure: technicalName === "schienale" ? "Schienale" : "Struttura",
    surface: "Finitura",
  };

  const getMaterialsArray = () => {
    if (type === "fabric") {
      return material;
    } else if (type === "structure") {
      return struttura;
    } else if (type === "surface") {
      return inserti;
    }
    return [];
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  // Special case: if `technicalName` === "ripiani"
  if (technicalName === "ripiani") {
    return (
      <div className="ay-w-full ay-p-4 ay-mt-4 ay-bg-white ay-mb-2 ay-border-b">
        <div
          className="ay-group ay-gap-x-4 ay-flex ay-justify-between ay-items-center"
          onClick={handleToggle}
        >
          <div>
            <h2 className="ay-text-lg">Ripiani</h2>
            <h5 className="ay-font-bold ay-text-lg ay-mt-1 ay-truncate">
              {selected?.slug
                ? `impostato: ${selected.slug}`
                : "Seleziona ripiani"}
            </h5>
          </div>
          <span
            className={`ay-transition-transform ay-duration-300 ${
              isOpen ? "ay-rotate-180" : ""
            }`}
          >
            <FaChevronDown />
          </span>
        </div>

        {isOpen && (
          <div className="ay-mt-4">
            {/* Metal finish radio buttons */}
            <div className="ay-mb-6">
              <h3 className="ay-text-sm ay-font-semibold ay-mb-2">
                Metal Finish
              </h3>
              <div className="ay-flex ay-gap-4 ay-mb-4">
                <label className="ay-flex ay-items-center ay-gap-2">
                  <input
                    type="radio"
                    name="ripianiFinish"
                    value="base"
                    checked={ripianiFinishType === "base"}
                    onChange={() => onRipianiFinishToggle("base")}
                  />
                  <span>Metal (Base)</span>
                </label>
                <label className="ay-flex ay-items-center ay-gap-2">
                  <input
                    type="radio"
                    name="ripianiFinish"
                    value="variation"
                    checked={ripianiFinishType === "variation"}
                    onChange={() => onRipianiFinishToggle("variation")}
                  />
                  <span>Metal lack (Var.)</span>
                </label>
              </div>
            </div>

            {/* Wood materials section */}
            {woodMaterials.length > 0 && (
              <div className="ay-mt-6">
                <h3 className="ay-text-sm ay-font-semibold ay-mb-2">
                  Wood Finish
                </h3>
                <div className="ay-grid ay-grid-cols-3 ay-gap-4">
                  {woodMaterials.map((material) => (
                    <div
                      key={material.slug}
                      className={`ay-cursor-pointer ay-text-center ${
                        selected?.slug === material.slug
                          ? "ay-ring-2 ay-ring-black"
                          : ""
                      }`}
                      onClick={() => onChange(material)}
                    >
                      <div className="ay-w-full ay-aspect-square ay-rounded-full ay-overflow-hidden ay-mb-2">
                        {material.thumbnail.startsWith("#") ? (
                          <div
                            className="ay-w-full ay-h-full"
                            style={{ backgroundColor: material.thumbnail }}
                          />
                        ) : (
                          <img
                            src={material.thumbnail}
                            alt={material.name}
                            className="ay-w-full ay-h-full ay-object-cover"
                          />
                        )}
                      </div>
                      <span className="ay-text-sm">{material.name}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  // For other materials (structure, fabric, surface), render the standard Accordion
  return (
    <Accordion
      title={titleMapping[type] || technicalName}
      materials={getMaterialsArray()}
      selected={selected}
      onChange={(m) => onChange(m)}
      customConfig={customConfig}
      defaultRivestimento={null}
      type={technicalName}
      resetMaterial={null}
    />
  );
}
