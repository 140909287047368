const products = [
  {
    name: "NAAA0001_L390_H420",
    code: "BEJSTUHULK",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0004_L390_H420",
    code: "A26IWWKPL2",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0007_L760_H420",
    code: "P47TLJP39M",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0010_L760_H420",
    code: "F7KIYBU05M",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0013_L1130_H420",
    code: "HBFEFHDDMU",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0016_L1130_H420",
    code: "A798GL0KSW",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0019_L390_H1040",
    code: "1WVMHP79CN",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0022_L390_H1040",
    code: "PYHEJJ6WOO",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0025_L760_H1040",
    code: "UMK87539WQ",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0028_L760_H1040",
    code: "2T49NBI2HA",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0031_L1130_H1040",
    code: "X5XHG9MEWU",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0034_L1130_H1040",
    code: "RTFGLS0F4V",
    customCollection: "line",
    family: "line",
  },

  {
    name: "NAAA0037_L1500_H1040",
    code: "DVIXGCRLRC",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0040_L1500_H1040",
    code: "6GIXOZPM37",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0043_L1870_H1040",
    code: "VXLC4C4QLF",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0046_L1870_H1040",
    code: "J2DODQE2KI",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0049_L390_H1410",
    code: "7KD2AX7RRM",
    customCollection: "line",
    family: "line",
  },

  {
    name: "NAAA0052_L390_H1410",
    code: "HU8J4DFUMD",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0055_L760_H1410",
    code: "I32XB2T8V0",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0058_L760_H1410",
    code: "46MNGYOJCK",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0061_L1130_H1410",
    code: "5ALHG0YLXD",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0064_L1130_H1410",
    code: "HE9FD48IQ3",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0067_L1500_H1410",
    code: "WG2SM4UJQ0",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0070_L1500_H1410",
    code: "URCT8XA4YU",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0073_L1870_H1410",
    code: "3NC5QFTQXC",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0076_L1870_H1410",
    code: "SYZA5604IG",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0079_L390_H1780",
    code: "PMPYQNBMX9",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0082_L390_H1780",
    code: "N7IG9A6SC0",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0085_L760_H1780",
    code: "ZW585FJ36I",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0088_L760_H1780",
    code: "R0FAZ043XA",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0091_L1130_H1780",
    code: "IFTQQQKL6Q",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0094_L1130_H1780",
    code: "JA0XCQEGUE",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0097_L1500_H1780",
    code: "P27O0D1IYQ",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0100_L1500_H1780",
    code: "HHE5RO76JV",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0103_L1870_H1780",
    code: "X6ZUO9PCDW",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0106_L1870_H1780",
    code: "NO8QHIJRFP",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0109_L390_H2150",
    code: "MDY8MSY60G",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0112_L390_H2150",
    code: "MFFGWUNBD1",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0115_L760_H2150",
    code: "YTHH7GTTSN",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0118_L760_H2150",
    code: "HVFLJGG9UR",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0121_L1130_H2150",
    code: "ZXQI21MXT1",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0124_L1130_H2150",
    code: "FTBSQ9AITK",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0127_L1500_H2150",
    code: "TP8PI2MSFY",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0130_L1500_H2150",
    code: "EK332F1T20",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0133_L1870_H2150",
    code: "6EICQRXYXB",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0136_L1870_H2150",
    code: "AR61NY7GCO",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0145_L1260_H420",
    code: "YKAFNNB9UT",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0148_L1260_H420",
    code: "DYYPF00GFH",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0151_L1880_H420",
    code: "721A4J4WOL",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0154_L1880_H420",
    code: "CUTFE331ZC",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0157_L640_H1040",
    code: "4JVABJZ7S0",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0160_L640_H1040",
    code: "HMHD4K15V8",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0163_L1260_H1040",
    code: "MWKKEY1MTL",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0166_L1260_H1040",
    code: "4HPTPU48RA",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0169_L1880_H1040",
    code: "CDLF5KAB9M",
    customCollection: "line",
    family: "line",
  },

  {
    name: "NAAA0172_L1880_H1040",
    code: "3PD9E7INLB",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0175_L640_H1410",
    code: "HW72Y3AHUY",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0178_L640_H1410",
    code: "9FUQW7HTA0",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0181_L1260_H1410",
    code: "I4XTZTZF20",
    customCollection: "line",
    family: "line",
  },

  {
    name: "NAAA0184_L1260_H1410",
    code: "KPMICRU6EN",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0187_L1880_H1410",
    code: "LYC5263FTQ",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0190_L1880_H1410",
    code: "PG2TPF5S2S",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0193_L640_H1780",
    code: "NQ7X8V76YU",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0196_L640_H1780",
    code: "B8KMHPI145",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0199_L1260_H1780",
    code: "QQW0FARK3V",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0202_L1260_H1780",
    code: "9WCLTHWK00",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0205_L1880_H1780",
    code: "02KX45YZOU",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0208_L1880_H1780",
    code: "V0LMS6H71J",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0211_L640_H2150",
    code: "AN9TH9FXBB",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0214_L640_H2150",
    code: "4MJZ16T60O",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0217_L1260_H2150",
    code: "UE4KJW3ALS",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0220_L1260_H2150",
    code: "MA87CBWZUX",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0223_L1880_H2150",
    code: "AVIR6VIFC7",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0226_L1880_H2150",
    code: "9N0EQFBFVF",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0229_L840_H420",
    code: "KOZAUB0SMY",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0232_L840_H420",
    code: "YELJFS02QY",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0235_L1660_H420",
    code: "FZSOMRBGAC",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0238_L1660_H420",
    code: "FB0UD8C0G4",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0241_L840_H1040",
    code: "14B9ZC3SD4",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0244_L840_H1040",
    code: "YN5MNQP85P",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0247_L1660_H1040",
    code: "61APXRFNUJ",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0250_L1660_H1040",
    code: "BGF9W5BI38",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0253_L840_H1410",
    code: "P04UMJ6JZT",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0256_L840_H1410",
    code: "VWIJKDG2OQ",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0259_L1660_H1410",
    code: "MTBGJVJ7MT",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0262_L1660_H1410",
    code: "NCSMP0HJRI",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0265_L800_H1780",
    code: "HM5OYOX3UN",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0268_L800_H1780",
    code: "3PR1QXK8Q5",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0271_L1660_H1780",
    code: "WMW70VTUHH",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0274_L1660_H1780",
    code: "SL6TTKQFY9",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0277_L840_H2150",
    code: "BTA7XCCQ11",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0280_L840_H2150",
    code: "T03ABB1PW3",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0283_L1660_H2150",
    code: "B6BF82R4KQ",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0286_L1660_H2150",
    code: "19DOF5LIO8",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0291_L2460_H1040",
    code: "ERR2EJKYBJ",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0292_L2460_H1040",
    code: "44J8X01LX5",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0293_L2460_H1410",
    code: "MYIV8KSA0N",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0294_L2460_H1410",
    code: "NHHU32TOLY",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0295_L2460_H1780",
    code: "O06RSH0YZE",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0296_L2460_H1780",
    code: "CVWE81T79W",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0297_L2460_H2150",
    code: "58SQL10D66",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0298_L2460_H2150",
    code: "JG664IYQXG",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0309_L1640_H1040",
    code: "PV9AT3LXXP",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0310_L1640_H1040",
    code: "938NVO5M31",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0311_L1640_H1410",
    code: "5TSAB7Y43Z",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0312_L1640_H1410",
    code: "IJP9QN712J",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0313_L1640_H1780",
    code: "620ZG5NDCE",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0314_L1640_H1780",
    code: "MYSDP7X1AP",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0315_L1640_H2150",
    code: "ZFXBR5USO2",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0316_L1640_H2150",
    code: "DOLYSFPR5D",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0319_CONTENITORE GIORNO L600",
    code: "NXLZDKSCPD",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0320_CONTENITORE GIORNO L800",
    code: "M3HSL7M8ED",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0321_CONTENITORE CASSETTO L600",
    code: "R2BYYOHCEW",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0322_CONTENITORE CASSETTO L800",
    code: "GF480WKPUW",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0323_CONTENITORE RIBALTA V_ALTO L600",
    code: "9GOI7ZOFNG",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0324_CONTENITORE RIBALTA V_ALTO L800",
    code: "SLLVPOUHNY",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0325_CONTENITORE RIBALTA V_BASSO L600",
    code: "EWCIZENC4D",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0326_CONTENITORE RIBALTA V_BASSO L800",
    code: "QZ0XSI8FKJ",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0327_SCRIVANIA_L1400",
    code: "XRJJPJBFWG",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0328_SCRIVANIA_L1600",
    code: "AQIVZ44E65",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0330",
    code: "YVHA5T07UN",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0331",
    code: "UJ6RCTLVHB",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0333",
    code: "CNSJX73SE2",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0334",
    code: "EILNYRLBE5",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0335",
    code: "SVBNVGS5GK",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0336",
    code: "Z0HMDND9SA",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0337",
    code: "4II3YS228X",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0338",
    code: "3RTDDW3OBM",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0339",
    code: "04JOH19Q6V",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0340_CUSCINO L1260",
    code: "J0SH1MGX5L",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0341_CUSCINO L1660",
    code: "547XMCIKKC",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0344_L1240_H1040",
    code: "THWCQ6QL6N",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0345_L1240_H1040",
    code: "ZUM64UBHSZ",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0346_L1240_H1410",
    code: "M68ZJ5NVWZ",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0347_L1240_H1410",
    code: "QTNCGMCLE4",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0348_L1240_H1780",
    code: "T0WJHHW7L4",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0349_L1240_H1780",
    code: "F4WBE041NJ",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0350_L1240_H2150",
    code: "W47SFQIZZI",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0351_L1240_H2150",
    code: "EG30QOSHF4",
    customCollection: "line",
    family: "line",
  },
  {
    name: "NDAA0001 2100 2560 CENTRO STANZA",
    code: "Q7AHHV0ASM",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0034 1400 700 SCHIENALE OV TAV DX TAV L SX",
    code: "LDDGK1S82W",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0033 1400 700 DIE SCHIENALI OVALI",
    code: "3DHUGCD33A",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0032 1400 700 SCHIENALE OV DX PUFF SX",
    code: "8PZFQDA5AN",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0031 1400 700 SCHIENALE OV SX PUFF DX",
    code: "K88FS93XPP",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0030 1400 700 SCHIENALE OV TAV DX PUFF SX",
    code: "ALAWDZD0GP",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0029 14000 700 SCHIENALE OV TAV SX TAV L DX",
    code: "BBJKJP4E3U",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0028 700 955 SCHIENALE OV TAV DX",
    code: "0319LQQDO2",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0027 700 955 SCHIENALE OV TAV SX",
    code: "6JWENL8RJ7",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0026 700 700 SCHIENALE OV",
    code: "WU35TLIM3P",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0025 1400 700 TAV DX TAV L SX",
    code: "8TVVIH92GA",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0024 1400 700 PUFF SX TAV L DX",
    code: "GS83P3KP5M",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0023 1400 700 TAV SX TAV L DX",
    code: "OYCDCBL1AQ",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0022 1400 700 TAV SX PUFF DX",
    code: "1J2UX8XF4F",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0021 1400 700 TAV DX PUFF SX",
    code: "AWWE50MBZA",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0020 1400 700 DUE PUFF",
    code: "RNC15LE43E",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0019 1400 700 SCHIENALE DX PUFF SX",
    code: "X5IP9KJ69Y",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0018 1400 700 SCHIENALE SX PUFF DX",
    code: "LRK8TC89D4",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0017 1400 700 SCHIENALE TAV DX PUFF SX",
    code: "E066C9OM6O",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0016 1400 700 SCHIENALE TAV DX TAV L SX",
    code: "VYRUSE9P4U",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0015 1400 700 DUE SCHIENALI",
    code: "GROFGMJ99P",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0014 1400 700 SCHIENALE TAV SX TAV L DX",
    code: "1LHQFVCH8H",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0013 700 700 TAVOLINO",
    code: "HKJD9D69R8",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0012-700 700 PUFF SCHIENALE ROTONDO",
    code: "TEI4QFMNRZ",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0011 700 955 SCHIENALE TAV SX",
    code: "QEMHM6ZSXJ",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0010 700 955 SCHIENALE TAV DX",
    code: "CBF4ZE6221",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0009 700 955 PUFF TAV SX",
    code: "76O046XJ7G",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0008 700 955 PUFF TAV DX",
    code: "DD7M3A4EL9",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0007 700 700 PUFF",
    code: "N4CP6A2QNU",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0006 2640 3000 PARETE",
    code: "VFXB4NN5QA",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0005 2400 2860 PARETE",
    code: "WCSF0EUFGB",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0004 - 2100 2560 PARETE",
    code: "13ZN09I1F8",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0003 2640 3000 CENTRO STANZA",
    code: "3UZ9XKM31J",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NDAA0002 2400 2860 CENTRO STANZA",
    code: "CYQOF5QEN0",
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "NMAA0007_1_con_cornice_legno 1000 450 200",
    code: "JJW7MAJY8S",
    customCollection: "line",
    family: "Bloomery",
  },
  {
    name: "NGAA0001 800 450 h880",
    code: "40013759UP",
    customCollection: "line",
    family: "Mate",
  },
  {
    name: "7CW0205-LIBRERIA_CWAVE_2CS_2086x300xH1873",
    code: "MMZNI5QMY0",
    family: "Cwave",
  },
  {
    name: "7EM30405 emeroteca",
    code: "F60WXFE7DM",
    customCollection: "line",
    family: "Chiave di volta",
  },
  {
    name: "7VL01B080_LIBRERE 8 RIPIANI H2427",
    code: "BBSMHHFEG1",
    customCollection: "line",
    family: "Chiave di volta",
  },
  {
    name: "3AG01H010-AGRESTE H500 PIANO MELAMINE Ø600",
    code: "C5VSSD8QVJ",
    customCollection: "line",
    family: "Ageste",
  },
  {
    name: "3AG03H030-AGRESTE H1100 PIANO MELAMINE Ø1000",
    code: "S526X2J6ND",
    customCollection: "line",
    family: "Ageste",
  },
  {
    name: "3AG03H020-AGRESTE H740 PIANO MELAMINE Ø1000",
    code: "GMSBUB8EYN",
    customCollection: "line",
    family: "Ageste",
  },
  {
    name: "3AG03H010-AGRESTE H500 PIANO MELAMINE Ø1000",
    code: "85L8VN2RDA",
    customCollection: "line",
    family: "Ageste",
  },
  {
    name: "3AG02H030-AGRESTE H1100 PIANO MELAMINE Ø790",
    code: "0O8J1W71OP",
    customCollection: "line",
    family: "Ageste",
  },
  {
    name: "3AG02H020-AGRESTE H740 PIANO MELAMINE Ø790",
    code: "B0QYUWT61L",
    customCollection: "line",
    family: "Ageste",
  },
  {
    name: "3AG02H010-AGRESTE H500 PIANO MELAMINE Ø790",
    code: "RP2H1CDFRI",
    customCollection: "line",
    family: "Ageste",
  },
  {
    name: "3ZT01H02_Zoey_Table",
    code: "FHTKY7THVW",
    customCollection: "line",
    family: "Zoey Table",
  },
  {
    name: "3HK30660_6600x1300xH740-MEETING",
    code: "YMW4GGBG5Y",
    customCollection: "line",
    family: "Tshare",
  },
  {
    name: "3HK20440_4400x1300xh740-MEETING",
    code: "DBLP99SVPR",
    customCollection: "line",
    family: "Tshare",
  },
  {
    name: "3H280130_2780x1300xh740-MEETING",
    code: "DR9JD4HP0L",
    customCollection: "line",
    family: "Tshare",
  },
  {
    name: "3H240130_2400x1300xh740-MEETING",
    code: "5QINZ5L9YC",
    customCollection: "line",
    family: "Tshare",
  },
  {
    name: "3H240090_2400x900xh740-MEETING",
    code: "8R92K60KER",
    customCollection: "line",
    family: "Tshare",
  },
  {
    name: "3H220130_2200x1300xh740-MEETING",
    code: "HRDTPWI9AW",
    customCollection: "line",
    family: "Tshare",
  },
  {
    name: "3H200090_2000x900xh740-MEETING",
    code: "AKNN5SK8MD",
    customCollection: "line",
    family: "Tshare",
  },
  {
    name: "3H200H90_2000x900xh1050-HIGHT",
    code: "VSEXVRTPN4",
    customCollection: "line",
    family: "Tshare",
  },
  {
    name: "3T100200_QUADRO_MEETING_TABLE_2000x1000xH740",
    code: "NP4A6M9WQ6",
    customCollection: "line",
    family: "Quadro Meeting",
  },
  {
    name: "3T100240_QUADRO_MEETING_TABLE_2400x1000xH740",
    code: "Y1U3DESZJ8",
    customCollection: "line",
    family: "Quadro Meeting",
  },
];

export default products;
