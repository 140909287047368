import React, { useState, useMemo } from "react";
import { switchConfiguration } from "./config";
import placeholder from "./assets/placeholder.jpg";
import { FaChevronDown } from "react-icons/fa";

// LEAVE THIS COMMENT
function getUniqueCategories(materials) {
  return [
    ...new Set(
      materials
        .filter((material) => material.category)
        .map((material) => material.category)
    ),
  ].filter(Boolean);
}

// LEAVE THIS COMMENT
const isHexColor = (str) => {
  return (
    str && typeof str === "string" && str.startsWith("#") && str.length === 7
  );
};

// LEAVE THIS COMMENT
const ThumbnailDisplay = ({ thumbnail, name, className = "" }) => {
  if (isHexColor(thumbnail)) {
    return (
      <div
        className={`${className} ay-rounded-full`}
        style={{
          backgroundColor: thumbnail,
          width: "100%",
          height: "100%",
          aspectRatio: "1/1",
        }}
      />
    );
  }

  return (
    <img
      className={`${className} ay-aspect-square ay-w-full ay-rounded-full ay-object-cover`}
      src={thumbnail || placeholder}
      alt={name}
    />
  );
};

function Accordion({
  title,
  materials,
  selected,
  onChange,
  customConfig,
  defaultRivestimento,
  type,
  resetMaterial,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(
    materials.length > 0 ? materials[0].category : null
  );
  const [openCollections, setOpenCollections] = useState({});
  const [selectedMaterial, setSelectedMaterial] = useState(null);

  // Filter logic for the displayed materials
  const filteredMaterials = useMemo(() => {
    let filtered = materials;
    if (customConfig?.customCollection) {
      filtered = materials.filter(
        (material) => material.collection === customConfig.customCollection
      );
    }
    // If you had category logic, you'd filter by selectedCategory.
    // (Currently, it's not always used, but we keep this structure.)
    return filtered.filter((material) =>
      selectedCategory ? material.category === selectedCategory : true
    );
  }, [materials, customConfig, selectedCategory]);

  const searchResults = useMemo(() => {
    if (!filterText.trim()) return [];
    let searchable = materials;
    if (customConfig?.customCollection) {
      searchable = materials.filter(
        (material) => material.collection === customConfig.customCollection
      );
    }
    return searchable
      .flatMap((material) => material.items)
      .filter((item) =>
        item.name.toLowerCase().includes(filterText.toLowerCase())
      );
  }, [filterText, materials, customConfig]);

  // LEAVE THIS COMMENT
  const handleMaterialChange = (newMaterial) => {
    if (newMaterial) {
      switchConfiguration(newMaterial, type);
      setSelectedMaterial(newMaterial);
      onChange(newMaterial);
    }
  };

  React.useEffect(() => {
    if (resetMaterial) {
      setSelectedMaterial(defaultRivestimento);
      resetMaterial();
    }
  }, [resetMaterial, defaultRivestimento]);

  const handleCategoryChange = (newCategory) => {
    setSelectedCategory(newCategory);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleCollectionToggle = (collection) => {
    setOpenCollections((prevState) => ({
      ...prevState,
      [collection]: !prevState[collection],
    }));
  };

  return (
    <div className="ay-w-full ay-p-4 ay-mt-4 ay-bg-white ay-mb-2 hover:ay-cursor-pointer ay-border-b">
      <div
        className="ay-group ay-gap-x-4 ay-flex ay-justify-between ay-items-center"
        onClick={handleToggle}
      >
        <div className="ay-w-16 ay-h-16 ay-flex-shrink-0 ay-overflow-hidden ay-rounded-full">
          <ThumbnailDisplay
            thumbnail={selected?.thumbnail}
            name={selected?.name}
            className="group-hover:ay-border ay-border-black ay-scale-125 group-hover:ay-scale-200 ay-transition-transform"
          />
        </div>
        <div className="ay-flex-1 ay-truncate">
          <h2 className="ay-text-lg">{title}</h2>
          <h5 className="ay-font-bold ay-text-lg ay-mt-1 ay-truncate">
            {selected?.slug ? `impostato: ${selected.slug}` : "seleziona"}
          </h5>
        </div>

        <span
          className={`ay-transition-transform ay-duration-300 ${
            isOpen ? "ay-rotate-180" : ""
          }`}
        >
          <FaChevronDown />
        </span>
      </div>

      {isOpen && (
        <div>
          {/* 
            Example of filters (commented out in your original code):
            {type === "imbottito" && !customConfig?.customCollection && (
              <>
                <div className="ay-mt-4">
                  <select
                    value={selectedCategory || ""}
                    onChange={(e) => handleCategoryChange(e.target.value)}
                    className="ay-border ay-rounded-full ay-boder-black ay-w-full ay-p-4"
                  >
                    <option key="all" value="">
                      TUTTE LE CATEGORIE
                    </option>
                    {getUniqueCategories(filteredMaterials).map((category) => (
                      <option key={category} value={category}>
                        {(category || "").toUpperCase()}
                      </option>
                    ))}
                  </select>
                </div>
                <input
                  type="text"
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                  placeholder={`Cerca ${title}...`}
                  className="ay-my-4 ay-p-4 ay-border ay-rounded-full ay-w-full"
                />
              </>
            )}
          */}

          {searchResults.length > 0 && (
            <div className="ay-search-results-popup">
              <div className="ay-category-items-grid ay-grid xl:ay-grid-cols-3 2xl:ay-grid-cols-3 lg:ay-grid-cols-2 md:ay-grid-cols-2 ay-grid-cols-3 ay-gap-4 ay-mt-2.5">
                {searchResults.map((result) => (
                  <div
                    key={result.slug}
                    className="ay-flex ay-flex-col ay-items-center ay-cursor-pointer"
                    onClick={() => handleMaterialChange(result)}
                  >
                    <div className="ay-overflow-hidden ay-rounded-full">
                      <ThumbnailDisplay
                        thumbnail={result.thumbnail}
                        name={result.name}
                        className="group-hover:ay-border ay-border-black ay-scale-125 hover:ay-scale-200 ay-transition-transform"
                      />
                    </div>
                    <p className="ay-mt-2 ay-text-center">{result.name}</p>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="ay-grid ay-grid-cols-2 ay-gap-4 ay-mt-4">
            {filteredMaterials.map((material) => {
              // We'll separate standard items vs. optional items inside each "material" collection
              const standardItems = material.items.filter(
                (item) => item.standard === "true"
              );
              const optionalItems = material.items.filter(
                (item) => item.standard !== "true"
              );

              return (
                <div
                  key={`${material.category}-${material.collection}`}
                  className="ay-col-span-2 ay-flex ay-flex-col"
                >
                  {/* If you want a label for each category/collection, you could add it here */}

                  {standardItems.length > 0 && (
                    <>
                      <div className="ay-border-b ay-my-2 ay-text-sm ay-flex ay-items-center">
                        <span>Standard</span>
                        <div className="ay-flex-1 ay-border-b ay-ml-2" />
                      </div>
                      <div className="ay-category-items-grid ay-grid ay-grid-cols-3 lg:ay-grid-cols-4 ay-gap-4 ay-mt-2.5">
                        {standardItems.map((item) => (
                          <div
                            key={item.slug}
                            className={`ay-material-item ay-group ay-relative ${
                              selected?.slug === item.slug ? "ay-active" : ""
                            }`}
                            onClick={() => handleMaterialChange(item)}
                          >
                            <div className="ay-overflow-hidden ay-rounded-full hover:ay-border-gray-300 hover:ay-border-2">
                              <ThumbnailDisplay
                                thumbnail={item.thumbnail}
                                name={item.name}
                                className="ay-group-hover:ay-border ay-scale-125 group-hover:ay-scale-200 ay-transition-transform"
                              />
                            </div>
                            <p className="ay-text-sm ay-mt-3.5">{item.name}</p>
                          </div>
                        ))}
                      </div>
                    </>
                  )}

                  {optionalItems.length > 0 && (
                    <>
                      <div className="ay-border-b ay-my-2 ay-text-sm ay-flex ay-items-center">
                        <span>Opzionali</span>
                        <div className="ay-flex-1 ay-border-b ay-ml-2" />
                      </div>
                      <div className="ay-category-items-grid ay-grid ay-grid-cols-3 lg:ay-grid-cols-4 ay-gap-4 ay-mt-2.5">
                        {optionalItems.map((item) => (
                          <div
                            key={item.slug}
                            className={`ay-material-item ay-group ay-relative ${
                              selected?.slug === item.slug ? "ay-active" : ""
                            }`}
                            onClick={() => handleMaterialChange(item)}
                          >
                            <div className="ay-overflow-hidden ay-rounded-full hover:ay-border-gray-300 hover:ay-border-2">
                              <ThumbnailDisplay
                                thumbnail={item.thumbnail}
                                name={item.name}
                                className="ay-group-hover:ay-border ay-scale-125 group-hover:ay-scale-200 ay-transition-transform"
                              />
                            </div>
                            <p className="ay-text-sm ay-mt-3.5">{item.name}</p>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export { Accordion };
