import placeholder from "../assets/placeholder.jpg";
import oak from "../assets/tiles/oak.jpg";

const struttura = [
  {
    category: "metal",
    collection: "line",
    items: [
      {
        name: " V1 Pure white",
        slug: " V1 Pure white",
        thumbnail: "#F5F5F5",
        standard: "true",
        variation: [
          {
            name: "ML 1 Pure white",
            slug: "ML 1 Pure white",
            thumbnail: "#F5F5F5",
          },
        ],
      },
      {
        name: "V50 Ochre brown",
        slug: "V50 Ochre brown",
        thumbnail: "#955F20",
        standard: "true",
        variation: [
          {
            name: "ML 3 Ochre brown",
            slug: "ML 3 Ochre brown",
            thumbnail: "#955F20",
          },
        ],
      },
      {
        name: "V38 Signal Black",
        slug: "V38 Signal Black",
        thumbnail: "#2C2C2C",
        standard: "true",
        variation: [
          {
            name: "ML 2 Signal Black",
            slug: "ML 2 Signal Black",
            thumbnail: "#2C2C2C",
          },
        ],
      },
      {
        name: "V47 Beige red",
        slug: "V47 Beige red",
        thumbnail: "#C1876B",
        variation: [
          {
            name: "ML 5 Beige red",
            slug: "ML 5 Beige red",
            thumbnail: "#C1876B",
          },
        ],
      },
      {
        name: "V65  Vermilion",
        slug: "V65  Vermilion",
        thumbnail: "#C0572F",
        variation: [
          {
            name: "ML 4 Vermilion",
            slug: "ML 4 Vermilion",
            thumbnail: "#C0572F",
          },
        ],
      },
      {
        name: "V46 Copper brown",
        slug: "V46 Copper brown",
        thumbnail: "#8F4E35",
        variation: [
          {
            name: "ML 10 Copper brown",
            slug: "ML 10 Copper brown",
            thumbnail: "#8F4E35",
          },
        ],
      },
      {
        name: "V68 Reseda green",
        slug: "V68 Reseda green",
        thumbnail: "#587246",
        variation: [
          {
            name: "ML 7 Reseda green",
            slug: "ML 7 Reseda green",
            thumbnail: "#587246",
          },
        ],
      },
      {
        name: "V8 Reed green",
        slug: "V8 Reed green",
        thumbnail: "#797957",
        variation: [
          {
            name: "ML 6 Reed green",
            slug: "ML 6 Reed green",
            thumbnail: "#797957",
          },
        ],
      },
      {
        name: "V69 Silver grey",
        slug: "V69 Silver grey",
        thumbnail: "#8A9597",
        variation: [
          {
            name: "ML 9 Silver grey",
            slug: "ML 9 Silver grey",
            thumbnail: "#8A9597",
          },
        ],
      },
      {
        name: "V70 Pastel turquoise",
        slug: "V70 Pastel turquoise",
        thumbnail: "#5b8a8b",
        variation: [
          {
            name: "ML 8 Pastel turquoise",
            slug: "ML 8 Pastel turquoise",
            thumbnail: "#5b8a8b",
          },
        ],
      },
      {
        name: " V33  Honey yellow",
        slug: " V33  Honey yellow",
        thumbnail: "#DBA10F",
        variation: [
          {
            name: "ML11 Honey yellow",
            slug: "ML11 Honey yellow",
            thumbnail: "#5b8a8b",
          },
        ],
      },
    ],
  },

  {
    category: "wood",
    collection: "line",
    items: [
      {
        name: " MWL1  Natural oak",
        slug: " MWL1  Natural oak",
        thumbnail: oak,
      },
    ],
  },
];
export default struttura;
