import tile_fenice from "./assets/tiles/fenice510.png";
import tile_limonta from "./assets/tiles/limonta.png";
import tile_newclassic from "./assets/tiles/newclassic.png";
import tile_pugi from "./assets/tiles/pugi.png";
import tile_urbanshadow from "./assets/tiles/urbanshadow.png";
import t83 from "./assets/tiles/t83.jpg";
import t84 from "./assets/tiles/t84.jpg";
import t85 from "./assets/tiles/t85.jpg";
import t86 from "./assets/tiles/t86.jpg";
import t87 from "./assets/tiles/t87.jpg";
import t88 from "./assets/tiles/t88.jpg";
import t89 from "./assets/tiles/t89.jpg";
import t102 from "./assets/tiles/t102.jpg";

const materiali = [
  {
    collection: "line",
    items: [
      {
        name: "T89 ",
        slug: "T89 ",
        thumbnail: t89,
        standard: "true",
      },
      {
        name: " T87  ",
        slug: " T87  ",
        thumbnail: t87,
        standard: "true",
      },
      {
        name: "T84 ",
        slug: "T84 ",
        thumbnail: t84,
        standard: "true",
      },
      {
        name: "T85",
        slug: "T85",
        thumbnail: t85,
      },
      {
        name: "T88  ",
        slug: "T88  ",
        thumbnail: t88,
      },
      {
        name: "T83 ",
        slug: "T83 ",
        thumbnail: t83,
      },
      {
        name: " T102  ",
        slug: " T102  ",
        thumbnail: t102,
      },
      {
        name: "T86  ",
        slug: "T86  ",
        thumbnail: t86,
      },
    ],
  },
];

export default materiali;
