// config.js
import { materialManager } from "./materialMapping";

import materiali from "./materials-stuff.js";

export const material = materiali;
export const defaultRivestimento = {
  slug: "",
  name: "",
  thumbnail: "",
};

export const defaultStruttura = {
  slug: "",
  name: "",
  thumbnail: "",
};

export const defaultFinitura = {
  slug: "",
  name: "",
  thumbnail: "",
};

export let configuration = {
  rivestimento: defaultRivestimento.slug,
};

export function switchConfiguration(configurationName, type) {
  console.log("=== SWITCH CONFIGURATION ===");
  console.log("Configuration Name:", configurationName);
  console.log("Type:", type);

  const technicalName = materialManager.getTechnicalName(type);
  console.log("Technical Name:", technicalName);

  emViewers["emersyaIframe"].setMaterials({
    materials: [
      {
        materialVariation: configurationName.slug,
        configurableMaterial: technicalName,
      },
    ],
  });

  console.log("Configuration Applied");
  console.log("=== END SWITCH CONFIGURATION ===");
}
