import React, { useCallback, useEffect, useRef, useState } from "react";
import icon_camera from "./assets/camera.svg";
import icon_plus from "./assets/plus.svg";
import icon_minus from "./assets/minus.svg";
import icon_autoRotate from "./assets/autoRotate.svg";
import icon_arrows_in from "./assets/arrowsIn.svg";
import icon_back from "./assets/arrowCounterClockwise.svg";
import { TbPerspective } from "react-icons/tb";

function Player({ productName, selectedProductCode }) {
  const emersyaIframeRef = useRef(null);
  const perspectiveMenuRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [viewerActive, setViewerActive] = useState(false);
  const [showViewMenu, setShowViewMenu] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        perspectiveMenuRef.current &&
        !perspectiveMenuRef.current.contains(event.target)
      ) {
        setShowViewMenu(false);
      }
    }

    // Add event listener when menu is open
    if (showViewMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showViewMenu]);

  // Initialize Emersya viewer
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://cdn.emersya.com/f/emersyaLoader.js";
    script.id = "emersya";

    script.setAttribute("routing", selectedProductCode);
    script.setAttribute("containerId", "emersyaIframe");

    document.head.appendChild(script);

    function initializeEmersyaAPI() {
      const emersyaViewer = emViewers["emersyaIframe"];
      setViewerActive(true);
    }

    document.addEventListener(
      "emersyaViewerInitialized",
      initializeEmersyaAPI,
      false
    );

    return () => {
      document.removeEventListener(
        "emersyaViewerInitialized",
        initializeEmersyaAPI
      );
      document.head.removeChild(script);
      setViewerActive(false);
    };
  }, [selectedProductCode]);

  // Define available views
  const views = {
    front: {
      name: "dfbfront",
      label: "FRONT VIEW",
    },
    back: {
      name: "dfbback",
      label: "BACK VIEW",
    },
    top: {
      name: "dfbtop",
      label: "TOP VIEW",
    },
    left: {
      name: "dfbleft",
      label: "LEFT VIEW",
    },
    right: {
      name: "dfbright",
      label: "RIGHT VIEW",
    },
    perspective: {
      name: "dfbperspective",
      label: "PERSPECTIVE VIEW",
    },
  };

  // View handling functions
  const handleViewClick = useCallback((view) => {
    const emersyaViewer = emViewers["emersyaIframe"];
    emersyaViewer.setViewpoint({
      name: view.name,
      transitionTime: 500,
      reinitializeZoomLimits: false,
    });
    setShowViewMenu(false);
  }, []);

  // Camera control functions
  const zoomIn = useCallback(() => {
    const emersyaViewer = emViewers["emersyaIframe"];
    emersyaViewer.startZoomIn().then(() => {
      setTimeout(() => {
        emersyaViewer.stopZoomIn();
      }, 800);
    });
  }, []);

  const zoomOut = useCallback(() => {
    const emersyaViewer = emViewers["emersyaIframe"];
    emersyaViewer.startZoomOut().then(() => {
      setTimeout(() => {
        emersyaViewer.stopZoomOut();
      }, 600);
    });
  }, []);

  const playRotation = useCallback(() => {
    const emersyaViewer = emViewers["emersyaIframe"];
    emersyaViewer.play();
  }, []);

  const resetCamera = useCallback(() => {
    const emersyaViewer = emViewers["emersyaIframe"];
    emersyaViewer.resetCamera();
  }, []);

  // Screenshot function
  const getScreenshot = useCallback(() => {
    const emersyaViewer = emViewers["emersyaIframe"];
    const date = new Date();
    const formattedDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;

    emersyaViewer.getCamera().then((cameraData) => {
      emersyaViewer
        .getScreenshot({
          width: 1024,
          height: 1024,
          transparentBackground: false,
          camera: cameraData,
        })
        .then((imageData) => {
          const downloadLink = document.createElement("a");
          downloadLink.href = imageData;
          downloadLink.download = `${formattedDate}-${productName}.png`;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        });
    });
  }, [productName]);

  const getCameraData = useCallback(() => {
    const emersyaViewer = emViewers["emersyaIframe"];

    emersyaViewer.getCamera().then((camera) => {
      const formattedData = {
        position: Array.from(camera.position),
        target: Array.from(camera.target),
        fov: camera.fov,
        zoom: camera.zoomProportion,
      };

      const output = JSON.stringify(formattedData, null, 2);

      // Create custom alert dialog
      const dialog = document.createElement("div");
      dialog.style.position = "fixed";
      dialog.style.top = "0";
      dialog.style.left = "0";
      dialog.style.width = "100%";
      dialog.style.height = "100%";
      dialog.style.backgroundColor = "rgba(0,0,0,0.5)";
      dialog.style.zIndex = "1000";
      dialog.style.display = "flex";
      dialog.style.alignItems = "center";
      dialog.style.justifyContent = "center";

      const content = document.createElement("div");
      content.style.background = "white";
      content.style.padding = "20px";
      content.style.borderRadius = "8px";
      content.style.maxWidth = "500px";
      content.style.width = "90%";

      const pre = document.createElement("pre");
      pre.style.marginBottom = "15px";
      pre.style.whiteSpace = "pre-wrap";
      pre.textContent = output;

      const copyButton = document.createElement("button");
      copyButton.textContent = "Copy Data";
      copyButton.style.padding = "8px 16px";
      copyButton.style.background = "#007bff";
      copyButton.style.color = "white";
      copyButton.style.border = "none";
      copyButton.style.borderRadius = "4px";
      copyButton.style.cursor = "pointer";
      copyButton.style.marginRight = "10px";
      copyButton.onclick = () => {
        navigator.clipboard.writeText(output).then(() => {
          copyButton.textContent = "Copied!";
          setTimeout(() => {
            copyButton.textContent = "Copy Data";
          }, 2000);
        });
      };

      const closeButton = document.createElement("button");
      closeButton.textContent = "Close";
      closeButton.style.padding = "8px 16px";
      closeButton.style.background = "#6c757d";
      closeButton.style.color = "white";
      closeButton.style.border = "none";
      closeButton.style.borderRadius = "4px";
      closeButton.style.cursor = "pointer";
      closeButton.onclick = () => dialog.remove();

      content.appendChild(pre);
      content.appendChild(copyButton);
      content.appendChild(closeButton);
      dialog.appendChild(content);
      document.body.appendChild(dialog);
    });
  }, [viewerActive]);

  return (
    <div className="ay-col-span-4 md:ay-col-span-3 md:ay-sticky ay-top-0 ay-h-fit ay-relative">
      {/* Left side controls */}
      <div className="ay-z-10 ay-absolute ay-left-5 ay-bottom-5 ay-flex ay-flex-col ay-gap-1">
        <p
          onClick={getCameraData}
          className="hover:ay-cursor-pointer ay-mt-2 ay-text-center ay-leading-[10px] ay-text-[8px]"
        >
          CAMERA <br /> DATA
        </p>

        <img
          onClick={playRotation}
          className="hover:ay-cursor-pointer ay-w-8"
          src={icon_autoRotate}
          alt="Auto Rotate"
        />
        <img
          onClick={zoomIn}
          className="hover:ay-cursor-pointer ay-w-8"
          src={icon_plus}
          alt="Zoom In"
        />
        <img
          onClick={zoomOut}
          className="hover:ay-cursor-pointer ay-w-8"
          src={icon_minus}
          alt="Zoom Out"
        />
        <img
          onClick={resetCamera}
          className="hover:ay-cursor-pointer ay-w-8"
          src={icon_back}
          alt="Reset Camera"
        />
        <img
          onClick={getScreenshot}
          className="hover:ay-cursor-pointer ay-w-8"
          src={icon_camera}
          alt="Take Screenshot"
        />
        {/* Right side perspective control */}
        <div ref={perspectiveMenuRef} className="">
          <div
            onClick={() => setShowViewMenu(!showViewMenu)}
            className="ay-w-8 ay-h-8 ay-flex ay-items-center ay-justify-center ay-bg-white ay-rounded-full ay-shadow-md"
          >
            <TbPerspective className="ay-w-6 ay-h-6 hover:ay-cursor-pointer" />
          </div>

          {showViewMenu && (
            <div className="ay-absolute ay-ml-2 ay-mb-5 ay-left-full ay-bottom-0 ay-bg-white ay-shadow-lg ay-py-2 ay-z-50">
              <div className="ay-flex ay-flex-col">
                {Object.values(views).map((view) => (
                  <button
                    key={view.name}
                    onClick={() => handleViewClick(view)}
                    className="ay-text-left ay-text-sm ay-px-4 ay-py-2 hover:ay-bg-gray-100 ay-text-[8px] ay-min-w-[80px] ay-whitespace-nowrap"
                  >
                    {view.label}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        className="ay-h-[60vh] md:ay-h-screen ay-w-full ay-relative"
        id="emersyaIframe"
        ref={emersyaIframeRef}
      ></div>
    </div>
  );
}

export default Player;
