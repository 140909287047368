// materialMapping.js
import material from "./materials-stuff";
import struttura from "./collections/struttura";
import inserti from "./collections/inserti";

export class MaterialManager {
  constructor() {
    this.configurableMaterials = [];
    this.materialTypeMap = {
      imbottito: "fabric",
      finitura: "fabric",
      ripiani: "structure",
      schienale: "structure",
      struttura: "structure",
      piano: "surface",
      inserti: "surface",
      plastica: "plastic",
    };
  }

  // Find existing material thumbnail from your material collections
  findExistingMaterial(materialName) {
    // Check in all material collections
    const collections = [material, struttura, inserti];

    // First try exact match on slug
    for (const collection of collections) {
      const foundMaterial = collection
        .flatMap((col) => col.items)
        .find((item) => item.slug === materialName);
      if (foundMaterial) {
        return foundMaterial;
      }
    }

    // If no exact match on slug, try name
    for (const collection of collections) {
      const foundMaterial = collection
        .flatMap((col) => col.items)
        .find((item) => item.name === materialName);
      if (foundMaterial) {
        return foundMaterial;
      }
    }

    return null;
  }

  initialize(viewerMaterials) {
    this.configurableMaterials = viewerMaterials
      .filter((material) => material.materialVariations.length > 1) // Filter out single variations
      .map((material) => ({
        type: this.materialTypeMap[material.name] || material.name,
        technicalName: material.name,
        variations: material.materialVariations.map((variation) => {
          // Try to find existing material
          const existingMaterial = this.findExistingMaterial(variation.name);
          return {
            slug: variation.name,
            name: variation.name.trim(),
            thumbnail:
              existingMaterial?.thumbnail || variation.color || "#808080",
          };
        }),
      }));
  }

  getMaterialType(technicalName) {
    return this.materialTypeMap[technicalName] || technicalName;
  }

  getMaterialByType(type) {
    return this.configurableMaterials.find(
      (m) => m.type === type || m.technicalName === type
    );
  }

  getVariationsForType(type) {
    const material = this.getMaterialByType(type);
    return material ? material.variations : [];
  }

  getTechnicalName(type) {
    const material = this.getMaterialByType(type);
    return material ? material.technicalName : type;
  }

  getAvailableMaterials() {
    return this.configurableMaterials;
  }
}

export const materialManager = new MaterialManager();
