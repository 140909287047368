// App.js
import React, { useEffect, useState } from "react";
import Player from "./Player";
import { Accordion } from "./Accordion";
import {
  material,
  defaultRivestimento,
  defaultStruttura,
  defaultFinitura,
  switchConfiguration,
} from "./config";
import struttura from "./collections/struttura";
import inserti from "./collections/inserti";
import products from "./products";
import logo from "./assets/logo-dieffebi.svg";
import FamilyAccordion from "./FamilyAccordion";
import FamilySelector from "./FamilySelector";
import { FaChevronRight } from "react-icons/fa";
import { materialManager } from "./materialMapping";
import { DynamicAccordion } from "./DynamicAccordion";

function App({ productId }) {
  const [loading, setLoading] = useState(true);
  const [selectedProductCode, setSelectedProductCode] = useState(productId);
  const [selectedFamily, setSelectedFamily] = useState(null);

  // RIVESTIMENTO
  const [selectedRivestimento, setSelectedRivestimento] =
    useState(defaultRivestimento);

  // STRUTTURA
  const [selectedStructureMaterials, setSelectedStructureMaterials] = useState({
    ripiani: defaultStruttura,
    schienale: defaultStruttura,
    struttura: defaultStruttura,
  });

  // FINITURA
  const [selectedFinitura, setSelectedFinitura] = useState(defaultFinitura);

  // This will hold whether ripiani uses base or variation
  const [ripianiFinishType, setRipianiFinishType] = useState("base");

  const [isAccordionHidden, setIsAccordionHidden] = useState(false);
  const [configurableMaterialOnClick, setConfigurableMaterialOnClick] =
    useState(null);
  const [viewerMaterials, setViewerMaterials] = useState([]);

  const toggleAccordionVisibility = () => {
    setIsAccordionHidden((prevState) => !prevState);
    setTimeout(() => {
      emViewers["emersyaIframe"].resize();
    }, 150);
  };

  const handleFamilyChange = (newFamilyName) => {
    setSelectedFamily(newFamilyName);
  };

  const selectedProduct =
    products.find((product) => product.code === selectedProductCode) || {};

  const setDefaultMaterials = (projectData) => {
    if (!projectData?.materialTrees) return;

    // Helper function to find material in collections
    const findMaterialInCollections = (slug) => {
      const collections = [material, struttura, inserti];
      for (const collection of collections) {
        const found = collection
          .flatMap((col) => col.items)
          .find(
            (item) => item.slug === slug || item.slug.trim() === slug.trim()
          );
        if (found) return found;
      }
      return null;
    };

    // Helper function to update ripiani if struttura changes
    const updateRipianiFromStruttura = (newMaterial, finishType) => {
      if (finishType === "variation" && newMaterial.variation?.length > 0) {
        return newMaterial.variation[0];
      }
      return newMaterial;
    };

    // Set default materials from materialTrees
    Object.entries(projectData.materialTrees).forEach(([key, value]) => {
      if (!value) return;

      let defaultMaterial = findMaterialInCollections(value);

      if (defaultMaterial) {
        switch (key) {
          case "imbottito":
          case "finitura":
            setSelectedRivestimento({
              slug: value,
              name: defaultMaterial.name,
              thumbnail: defaultMaterial.thumbnail,
            });
            break;
          case "struttura":
          case "ripiani":
          case "schienale":
            if (key === "struttura") {
              setSelectedStructureMaterials((prev) => ({
                ...prev,
                struttura: {
                  slug: value,
                  name: defaultMaterial.name,
                  thumbnail: defaultMaterial.thumbnail,
                  variation: defaultMaterial.variation,
                },
                ripiani: updateRipianiFromStruttura(
                  {
                    slug: value,
                    name: defaultMaterial.name,
                    thumbnail: defaultMaterial.thumbnail,
                    variation: defaultMaterial.variation,
                  },
                  ripianiFinishType
                ),
              }));
            } else {
              setSelectedStructureMaterials((prev) => ({
                ...prev,
                [key]: {
                  slug: value,
                  name: defaultMaterial.name,
                  thumbnail: defaultMaterial.thumbnail,
                  variation: defaultMaterial.variation,
                },
              }));
            }
            break;
          case "inserti":
          case "piano":
            setSelectedFinitura({
              slug: value,
              name: defaultMaterial.name,
              thumbnail: defaultMaterial.thumbnail,
            });
            break;
        }
      }
    });

    const emersyaViewer = emViewers["emersyaIframe"];

    emersyaViewer.setViewpoint({
      name: "dfbfront",
      transitionTime: 500,
      reinitializeZoomLimits: true,
    });

    setTimeout(() => {
      emersyaViewer.play();
    }, 2000);

    setLoading(false);
  };

  useEffect(() => {
    function initializeEmersyaAPI() {
      const emersyaViewer = emViewers["emersyaIframe"];

      emersyaViewer.addEventListener(
        "onConfigurableMaterialHighlight",
        function (event) {
          if (event[0] && event[0].indexOf("imbottito_") === 0) {
            setConfigurableMaterialOnClick(event[0]);
          } else {
            setConfigurableMaterialOnClick(null);
          }
        }
      );

      emersyaViewer.addEventListener("onStateChange", (data) => {
        if (data.viewerState === "loaded") {
          emersyaViewer.getCurrentGlobalConfiguration().then((data) => {
            const currentProject = Object.keys(data.projectsData)[0];
            const projectData = data.projectsData[currentProject];
            setDefaultMaterials(projectData);

            // Initialize material manager with viewer materials
            emersyaViewer
              .getProductNodeInstanceConfigurableMaterials({
                localId: data.sceneGraph.localId,
              })
              .then((configurableMaterials) => {
                console.log(emersyaViewer);
                console.log(data);
                console.log("Configurable Materials:", configurableMaterials);
                console.log(
                  emersyaViewer.getCurrentProductNodesScreenPosition({
                    localId: data.sceneGraph.localId,
                  })
                );
                materialManager.initialize(configurableMaterials);
                setViewerMaterials(materialManager.getAvailableMaterials());
                setLoading(false);
              });
          });
        }
      });
    }

    document.addEventListener(
      "emersyaViewerInitialized",
      initializeEmersyaAPI,
      false
    );

    return () => {
      document.removeEventListener(
        "emersyaViewerInitialized",
        initializeEmersyaAPI
      );
    };
  }, []);

  const handleProductChange = (newProductCode) => {
    if (!newProductCode) return;

    setLoading(true);
    setSelectedProductCode(newProductCode);
    const newProduct = products.find(
      (product) => product.code === newProductCode
    );

    if (newProduct) {
      setSelectedRivestimento(defaultRivestimento);
      setSelectedStructureMaterials({
        ripiani: defaultStruttura,
        schienale: defaultStruttura,
        struttura: defaultStruttura,
      });
      setSelectedFinitura(defaultFinitura);
      setConfigurableMaterialOnClick(null);
      setRipianiFinishType("base");
    }
  };

  // UPDATED handleMaterialChange function:
  const handleMaterialChange = (newMaterial, materialType) => {
    const actualType =
      materialType === "struttura"
        ? "struttura"
        : configurableMaterialOnClick || materialType;
    const materialCategory = materialManager.getMaterialType(actualType);

    if (materialCategory === "structure") {
      if (actualType === "struttura") {
        // 1. Determine ripiani material
        const ripianiMaterial =
          ripianiFinishType === "variation" && newMaterial.variation
            ? newMaterial.variation[0]
            : newMaterial;

        // 2. Update state
        setSelectedStructureMaterials((prev) => ({
          ...prev,
          struttura: newMaterial,
          ripiani: ripianiMaterial,
        }));

        // 3. Apply materials with a small delay between them
        const applyMaterials = async () => {
          // First apply struttura
          await new Promise((resolve) => {
            emViewers["emersyaIframe"].setMaterials({
              materials: [
                {
                  materialVariation: newMaterial.slug,
                  configurableMaterial: "struttura",
                },
              ],
            });
            setTimeout(resolve, 50);
          });

          // Then apply ripiani
          emViewers["emersyaIframe"].setMaterials({
            materials: [
              {
                materialVariation: ripianiMaterial.slug,
                configurableMaterial: "ripiani",
              },
            ],
          });
        };

        applyMaterials();
      } else if (actualType === "ripiani") {
        setSelectedStructureMaterials((prev) => ({
          ...prev,
          ripiani: newMaterial,
        }));
        switchConfiguration(newMaterial, "ripiani");
      } else if (actualType === "schienale") {
        setSelectedStructureMaterials((prev) => ({
          ...prev,
          schienale: newMaterial,
        }));
        switchConfiguration(newMaterial, "schienale");
      }
    } else if (materialCategory === "fabric") {
      setSelectedRivestimento(newMaterial);
      switchConfiguration(newMaterial, actualType);
    } else if (materialCategory === "surface") {
      setSelectedFinitura(newMaterial);
      switchConfiguration(newMaterial, actualType);
    }

    emViewers["emersyaIframe"].setHighlight({ configurableMaterials: [""] });
  };

  // This function is called by the ripiani toggle (Metal / Metal lack)
  const handleRipianiFinishToggle = (finishType) => {
    setRipianiFinishType(finishType);

    // Get the current struttura material
    const currentStruttura = selectedStructureMaterials.struttura;
    if (!currentStruttura?.slug) return;

    if (finishType === "variation") {
      if (currentStruttura.variation?.length) {
        handleMaterialChange(currentStruttura.variation[0], "ripiani");
      } else {
        handleMaterialChange(currentStruttura, "ripiani");
      }
    } else {
      handleMaterialChange(currentStruttura, "ripiani");
    }
  };

  return (
    <>
      {loading && (
        <div
          className="ay-w-full ay-h-screen ay-bg-white ay-opacity-95 ay-fixed ay-top-0 ay-z-50 ay-flex ay-justify-center ay-items-center ay-text-3xl"
          id="loader"
        >
          <img
            src={logo}
            alt="Logo dieffebi"
            className="ay-animate-scale-pulse"
          />
        </div>
      )}

      <div className="ay-flex ay-flex-col md:ay-flex-row ay-h-screen ay-overflow-hidden">
        <div
          className={`ay-relative ${
            isAccordionHidden ? "ay-w-full" : "ay-w-full md:ay-w-4/5"
          } ay-transition-all duration-500 ay-ease-in-out`}
        >
          <Player selectedProductCode={selectedProductCode} />
          <button
            onClick={toggleAccordionVisibility}
            className={`ay-absolute ay-transition-transform ay-duration-300 ${
              isAccordionHidden ? "ay-rotate-180" : ""
            } ay-right-4 ay-opacity-20 ay-top-1/2 ay-transform ay--translate-y-1/2 ay-text-3xl ay-font-bold ay-focus:ay-outline-none`}
          >
            <FaChevronRight />
          </button>
        </div>

        <div
          className={`ay-p-5 ay-bg-[#f9f9f9] ay-overflow-auto ${
            isAccordionHidden ? "ay-w-0" : "ay-w-full md:ay-w-3/5 lg:ay-w-2/6"
          } ay-transition-all duration-500 ay-ease-in-out`}
          style={{ display: isAccordionHidden ? "none" : "block" }}
        >
          <div className="grid grid-cols-3 ay-h-full">
            <FamilySelector
              products={products}
              selectedFamily={selectedProduct.family}
              onFamilyChange={handleFamilyChange}
              onProductChange={handleProductChange}
            />

            <FamilyAccordion
              products={products}
              selectedProduct={selectedProduct}
              onProductChange={handleProductChange}
              selectedFamily={selectedFamily}
            />

            <div>
              {viewerMaterials.map((material) => (
                <DynamicAccordion
                  key={material.technicalName}
                  type={material.type}
                  technicalName={material.technicalName}
                  selected={
                    material.type === "fabric"
                      ? selectedRivestimento
                      : material.type === "structure"
                      ? selectedStructureMaterials[material.technicalName]
                      : material.type === "surface"
                      ? selectedFinitura
                      : null
                  }
                  onChange={(newMaterial) =>
                    handleMaterialChange(newMaterial, material.technicalName)
                  }
                  customConfig={
                    selectedProduct.customCollection &&
                    selectedProduct.customMaterial
                      ? {
                          customCollection: selectedProduct.customCollection,
                          customMaterial: selectedProduct.customMaterial,
                        }
                      : null
                  }
                  ripianiFinishType={ripianiFinishType}
                  onRipianiFinishToggle={handleRipianiFinishToggle}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
